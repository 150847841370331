import './AboutInfo.css';
import unidadegocaremobile from '../../../../assets/img-main/unidadegocaremobile.png'

const AboutInfo = () => {
    return (
        <div className='about__info'>
            <div className='about__info__text'>
                <h2 className='about__title'>
                    A <span>GoCare Saúde</span>
                </h2>
                <p className='about__paragraph'>
                    Nosso objetivo é tornar a saúde mais acessível e simples desde o primeiro contato. O Grupo GoCare cresce com a missão de cuidar da saúde física e mental, oferecendo desde prevenção até tratamentos especializados. Cuidamos do seu sorriso e da sua saúde de forma integral, sempre ao seu lado.
                </p>
                <p className='about__paragraph'>
                    Atuando em todo o interior do Estado de São Paulo, a GoCare oferece uma ampla rede credenciada para atender seus beneficiários, com preços justos que cabem no seu orçamento.
                </p>
                <p className='about__paragraph'>
                    A GoCare se destaca por ter os melhores hospitais e profissionais renomados em sua rede de prestadores de serviços. Registrada na ANS – Agência Nacional de Saúde sob o número 42.268-1, a GoCare oferece cobertura completa em planos de saúde que se adaptam às suas necessidades e às de sua empresa.
                </p>
                <p className='about__paragraph'>
                    A GoCare é para sua família, para sua empresa, a GoCare é para você!
                </p>
                <h3 className='about__subtitle'>
                    Porque <span>sua saúde</span> é um plano de agora!
                </h3>
            </div>
            <div>
                <img
                    src={unidadegocaremobile}
                    alt="Unidade Plano Santa Saúde"
                />
            </div>
        </div>
    );
}

export default AboutInfo;
