import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './Carousel.css';

import banner_adesao from '../../../../assets/img-main/carousel/banner_adesao.png';
import banner_adesao_mobile from '../../../../assets/img-main/carousel/banner_adesao_mobile.png';
import banner_empresarial from '../../../../assets/img-main/carousel/banner_empresarial.png';
import banner_empresarial_mobile from '../../../../assets/img-main/carousel/banner_empresarial_mobile.png';
import banner_adesaoestudantil from '../../../../assets/img-main/carousel/banner_adesaoestudantil.png';
import banner_adesaoestudantil_mobile from '../../../../assets/img-main/carousel/banner_adesaoestudantil_mobile.png';
import banner_indfam from '../../../../assets/img-main/carousel/banner_indfam.png';
import banner_indfam_mobile from '../../../../assets/img-main/carousel/banner_indfam_mobile.png';
import banner_farma from '../../../../assets/img-main/carousel/banner_farma.png';
import banner_farma_mobile from '../../../../assets/img-main/carousel/banner_farma_mobile.png';
import banner_odonto from '../../../../assets/img-main/carousel/banner_odonto.png';
import banner_odonto_mobile from '../../../../assets/img-main/carousel/banner_odonto_mobile.png';

const Carousel = () => {
  const images = [
    { small: banner_adesao_mobile, large: banner_adesao },
    { small: banner_odonto_mobile, large: banner_odonto },
    { small: banner_empresarial_mobile, large: banner_empresarial },
    { small: banner_adesaoestudantil_mobile, large: banner_adesaoestudantil },
    { small: banner_indfam_mobile, large: banner_indfam },
    { small: banner_farma_mobile, large: banner_farma },
  ];

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/cliente');
  };

  return (
    <section className="carousel-container">
      <Swiper
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 7000 }}
        navigation={true}
        pagination={{ clickable: true }}
        className="custom-swiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <picture onClick={handleClick}>
              <source media="(min-width: 768px)" srcSet={image.large} />
              <source media="(max-width: 767px)" srcSet={image.small} />
              <img
                src={image.large}
                alt={`Slide ${index + 1}`}
              />
            </picture>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Carousel;
