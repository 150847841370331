const RedesPeruibe = [
    {
        especialidade: "Cirurgia Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Dr Dente",
                endereco: "Avenida Tangredo de Almeida Neves, 2439, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 99130-0740"],
            },
            {
                nome: "Smart Clinic",
                endereco: "Avenida Padre Leonardo Nunes, 405, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3411-3716", "(13) 97800-9975"],
            }
        ],
    },
    {
        especialidade: "Clínica Geral Odontológica",
        estabelecimentos: [
            {
                nome: "A.C. Odontologia Estética",
                endereco: "Rua Domingos da Costa, 219, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 99600-5180"],
            },
            {
                nome: "Dr Dente",
                endereco: "Avenida Tangredo de Almeida Neves, 2439, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 99130-0740"],
            },
            {
                nome: "Smart Clinic",
                endereco: "Avenida Padre Leonardo Nunes, 405, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3411-3716", "(13) 97800-9975"],
            }
        ],
    },
    {
        especialidade: "Dentística",
        estabelecimentos: [
            {
                nome: "A.C. Odontologia Estética",
                endereco: "Rua Domingos da Costa, 219, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 99600-5180"],
            },
            {
                nome: "Dr Dente",
                endereco: "Avenida Tangredo de Almeida Neves, 2439, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 99130-0740"],
            },
            {
                nome: "Radiographic",
                endereco: "Avenida Taguaritinga, 284, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3453-4438"],
            },
            {
                nome: "Smart Clinic",
                endereco: "Avenida Padre Leonardo Nunes, 405, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3411-3716", "(13) 97800-9975"],
            }
        ],
    },
    {
        especialidade: "Endodontia",
        estabelecimentos: [
            {
                nome: "A.C. Odontologia Estética",
                endereco: "Rua Domingos da Costa, 219, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 99600-5180"],
            },
            {
                nome: "Smart Clinic",
                endereco: "Avenida Padre Leonardo Nunes, 405, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3411-3716", "(13) 97800-9975"],
            }
        ],
    },
    {
        especialidade: "Fonoaudiologia",
        estabelecimentos: [
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares",
                endereco: "Avenida Governador Mario Covas Junior, 8979, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3357-0601", "(13) 97805-7698"],
            }
        ],
    },
    {
        especialidade: "Musicoterapia",
        estabelecimentos: [
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares",
                endereco: "Avenida Governador Mario Covas Junior, 8979, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3357-0601", "(13) 97805-7698"],
            }
        ],
    },
    {
        especialidade: "Psicologia",
        estabelecimentos: [
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares",
                endereco: "Avenida Governador Mario Covas Junior, 8979, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3357-0601", "(13) 97805-7698"],
            }
        ],
    },
    {
        especialidade: "Psicomotricidade",
        estabelecimentos: [
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares",
                endereco: "Avenida Governador Mario Covas Junior, 8979, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3357-0601", "(13) 97805-7698"],
            }
        ],
    },
    {
        especialidade: "Psicopedagogia",
        estabelecimentos: [
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares",
                endereco: "Avenida Governador Mario Covas Junior, 8979, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3357-0601", "(13) 97805-7698"],
            }
        ],
    },
    {
        especialidade: "Terapia Ocupacional",
        estabelecimentos: [
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares",
                endereco: "Avenida Governador Mario Covas Junior, 8979, Centro, Peruíbe / SP - CEP 11750-000",
                telefones: ["(13) 3357-0601", "(13) 97805-7698"],
            }
        ],
    },
];

export default RedesPeruibe;