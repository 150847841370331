const RedesBertioga = [
  {
    especialidade: "Anatomia Patológica",
    estabelecimentos: [
      {
        nome: "Jac Laboratório de Análises Clínicas LTDA",
        endereco: "Rua Antonio Rodrigues de Almeida, 595, Centro, Bertioga / SP - CEP 11250-267",
        telefones: [],
      },
    ],
  },
  {
    especialidade: "Cirurgia Geral Odontológica",
    estabelecimentos: [
      {
        nome: "Antônio Sergio Lisa",
        endereco: "Rua Rafael Costabile, 223, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-7891", "(11) 97373-2023"],
      },
      {
        nome: "Cristiane Frenkiel Van Rossum Martins da Costa",
        endereco: "Praça Rua Claudio Cezar de Aguiar Mauriz, 365, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-5426", "(13) 99650-0664"],
      },
    ],
  },
  {
    especialidade: "Citopatologia",
    estabelecimentos: [
      {
        nome: "Jac Laboratório de Análises Clínicas LTDA",
        endereco: "Rua Antonio Rodrigues de Almeida, 595, Centro, Bertioga / SP - CEP 11250-267",
        telefones: [],
      },
    ],
  },
  {
    especialidade: "Clínica Geral Odontológica",
    estabelecimentos: [
      {
        nome: "Antônio Sergio Lisa",
        endereco: "Rua Rafael Costabile, 223, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-7891", "(11) 97373-2023"],
      },
      {
        nome: "Cristiane Frenkiel Van Rossum Martins da Costa",
        endereco: "Praça Rua Claudio Cezar de Aguiar Mauriz, 365, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-5426", "(13) 99650-0664"],
      },
    ],
  },
  {
    especialidade: "Densitometria Óssea",
    estabelecimentos: [
      {
        nome: "Clinica Mult Imagem",
        endereco: "Rua Luiz Pereira de Campos, 119, Vila Itapanhau, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3316-4448"],
      },
    ],
  },
  {
    especialidade: "Dentística",
    estabelecimentos: [
      {
        nome: "Antônio Sergio Lisa",
        endereco: "Rua Rafael Costabile, 223, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-7891", "(11) 97373-2023"],
      },
      {
        nome: "Bio Fx",
        endereco: "Praça Vicente Molinari, 357, Vila Itapanhau, Bertioga / SP - CEP 11250-294",
        telefones: ["(13) 3317-6685"],
      },
      {
        nome: "Cristiane Frenkiel Van Rossum Martins da Costa",
        endereco: "Praça Rua Claudio Cezar de Aguiar Mauriz, 365, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-5426", "(13) 99650-0664"],
      },
    ],
  },
  {
    especialidade: "Endodontia",
    estabelecimentos: [
      {
        nome: "Antônio Sergio Lisa",
        endereco: "Rua Rafael Costabile, 223, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-7891", "(11) 97373-2023"],
      },
      {
        nome: "Cristiane Frenkiel Van Rossum Martins da Costa",
        endereco: "Praça Rua Claudio Cezar de Aguiar Mauriz, 365, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-5426", "(13) 99650-0664"],
      },
    ],
  },
  {
    especialidade: "Mamografia",
    estabelecimentos: [
      {
        nome: "Clinica Mult Imagem",
        endereco: "Rua Luiz Pereira de Campos, 119, Vila Itapanhau, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3316-4448"],
      },
    ],
  },
  {
    especialidade: "Medicina Laboratorial",
    estabelecimentos: [
      {
        nome: "Itapema Laboratório",
        endereco: "Avenida Anchieta, 1014, Bertioga, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-4210"],
      },
      {
        nome: "Jac Laboratório de Análises Clínicas LTDA",
        endereco: "Rua Antonio Rodrigues de Almeida, 595, Centro, Bertioga / SP - CEP 11250-267",
        telefones: [],
      },
    ],
  },
  {
    especialidade: "Odontopediatria",
    estabelecimentos: [
      {
        nome: "Antônio Sergio Lisa",
        endereco: "Rua Rafael Costabile, 223, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-7891", "(11) 97373-2023"],
      },
    ],
  },
  {
    especialidade: "Ortodontia",
    estabelecimentos: [
      {
        nome: "Orto & Implante",
        endereco: "Rua Bartolomeu Fernandes Gonçalves, 380, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-5142", "(13) 98125-1100"],
      },
    ],
  },
  {
    especialidade: "Prótese Dentária",
    estabelecimentos: [
      {
        nome: "Antônio Sergio Lisa",
        endereco: "Rua Rafael Costabile, 223, Centro, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3317-7891", "(11) 97373-2023"],
      },
    ],
  },
  {
    especialidade: "RX",
    estabelecimentos: [
      {
        nome: "Clinica Mult Imagem",
        endereco: "Rua Luiz Pereira de Campos, 119, Vila Itapanhau, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3316-4448"],
      },
    ],
  },
  {
    especialidade: "Tomografia Computadorizada",
    estabelecimentos: [
      {
        nome: "Clinica Mult Imagem",
        endereco: "Rua Luiz Pereira de Campos, 119, Vila Itapanhau, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3316-4448"],
      },
    ],
  },
  {
    especialidade: "Ultrassonografia Geral",
    estabelecimentos: [
      {
        nome: "Clinica Mult Imagem",
        endereco: "Rua Luiz Pereira de Campos, 119, Vila Itapanhau, Bertioga / SP - CEP 11250-000",
        telefones: ["(13) 3316-4448"],
      },
    ],
  },
];

export default RedesBertioga;
