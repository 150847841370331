import './Wpp.css';
import wppicon from '../../../assets/img-main/wpplogo.png';

const Wpp = () => {
    return (
        <div className="wpp__button">
            <a
                href="https://api.whatsapp.com/send?phone=5513981260507&text=Olá,%20estou%20entrando%20em%20contato%20para%20obter%20informações%20sobre%20a%20GoCare%20Litoral."
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className="button-text">
                    VENDAS
                </div>
                <div className="button-content">
                    <img
                        src={wppicon}
                        alt='ícone WhatsApp'
                        className="wpp-icon"
                    />
                </div>
            </a>
        </div>
    );
};

export default Wpp;
