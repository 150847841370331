import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import './Client.css';

import logo2 from "../../../assets/logo2.png";
import cliente_info from "../../../assets/img-cliente/cliente-info.png"

import Form from './form/Form.js';
import usePageTracking from '../../../hooks/usePageTracking.js';


function Client() {
  usePageTracking();

  const navigate = useNavigate();

  const handleFormSubmitted = () => {
    navigate('/confirmacao');
  };

  return (
    <div className="contact">
      <Helmet>
        <title>Seja Cliente | GoCare Saúde Litoral</title>
        <meta name="description" content="Torne-se cliente da GoCare Saúde e tenha acesso a um atendimento médico de qualidade com cobertura completa na Baixada Santista. Contrate seu plano de saúde de forma rápida e fácil." />


        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Plano de Saúde",
            "provider": {
              "@type": "Organization",
              "name": "GoCare Saúde Litoral",
              "url": "https://gocarelitoral.com.br",
              "description": "Torne-se cliente da GoCare Saúde e tenha acesso a um atendimento médico de qualidade com cobertura completa na Baixada Santista. Contrate seu plano de saúde de forma rápida e fácil.",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+55 13 98126-0507",
                "contactType": "sales",
                "areaServed": "BR",
                "availableLanguage": ["Portuguese"],
              }
            }
          })}
        </script>

      </Helmet>

      <div className="contact__info">

        <img className="contact__logo" src={logo2} alt="Logo da empresa" />

        <div className='contact__subtitle__container'>

          <p className="contact__subtitle">A <span>GoCare</span> é<br /> para sua <span>família</span>,<br /> para sua <span>empresa</span>,<br /> a <span>GoCare</span> é para <span>você</span>!<br /></p>
          <p className="contact__p">Economize até 40% utilizando <br />seu CNPJ a partir de 2 vidas!</p>

        </div>

        <img className='contact__cliente__img' src={cliente_info} alt='Imagem de uma família' />

      </div>

      <div className='form_grid'>

        <Form onFormSubmitted={handleFormSubmitted} />

      </div>

    </div>
  );
}

export default Client;
