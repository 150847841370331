import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import './Rede.css';

import CityCard from './CityCard/CityCard';
import CityModal from './CityModal';

import RedesBertioga from './ListaRede/RedeBertioga';
import RedesCubatao from './ListaRede/RedeCubatao';
import RedesGuaruja from './ListaRede/RedeGuaruja';
import RedesItanhaem from './ListaRede/RedeItanhaem';
import RedesMongagua from './ListaRede/RedeMongagua';
import RedesPraiaGrande from './ListaRede/RedePraiaGrande';
import RedesSantos from './ListaRede/RedeSantos';
import RedesSaoVicente from './ListaRede/RedeSaoVicente';
import RedesPeruibe from './ListaRede/RedePeruibe';

const Redes = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [isCityModalOpen, setIsCityModalOpen] = useState(false);

    const cities = [
        { nome: 'Bertioga', redes: RedesBertioga, downloadUrl: '/assets/downloads/RedeCred-Bertioga-GoCareLitoral.pdf' },
        { nome: 'Cubatão', redes: RedesCubatao, downloadUrl: '/assets/downloads/RedeCred-Cubatao-GoCareLitoral.pdf' },
        { nome: 'Guarujá', redes: RedesGuaruja, downloadUrl: '/assets/downloads/RedeCred-Guaruja-GoCareLitoral.pdf' },
        { nome: 'Itanhaém', redes: RedesItanhaem, downloadUrl: '/assets/downloads/RedeCred-Itanhaem-GoCareLitoral.pdf' },
        { nome: 'Mongaguá', redes: RedesMongagua, downloadUrl: '/assets/downloads/RedeCred-Mongagua-GoCareLitoral.pdf' },
        { nome: 'Peruíbe', redes: RedesPeruibe, downloadUrl: '/assets/downloads/RedeCred-Peruibe-GoCareLitoral.pdf' },
        { nome: 'Praia Grande', redes: RedesPraiaGrande, downloadUrl: '/assets/downloads/RedeCred-PraiaGrande-GoCareLitoral.pdf' },
        { nome: 'Santos', redes: RedesSantos, downloadUrl: '/assets/downloads/RedeCred-Santos-GoCareLitoral.pdf' },
        { nome: 'São Vicente', redes: RedesSaoVicente, downloadUrl: '/assets/downloads/RedeCred-SaoVicente-GoCareLitoral.pdf' },
    ];



    const openCityModal = (city) => {
        setSelectedCity(city);
        setIsCityModalOpen(true);
    };

    const closeCityModal = () => {
        setSelectedCity(null);
        setIsCityModalOpen(false);
    };

    return (
        <section className='redes-container'>
            <Helmet>
                <title>Rede Credenciada | GoCare Saúde Litoral</title>
                <meta
                    name="description"
                    content="Explore a rede credenciada da GoCare Saúde no Litoral. Visualize ou faça o download das informações sobre profissionais e estabelecimentos médicos em sua região."
                />
            </Helmet>
            <div className='redes-description'>
                <h1>
                    CONHEÇA NOSSA <span>AMPLA REDE CREDENCIADA</span>
                </h1>
                <p>
                    Explore a nossa rede credenciada, cuidadosamente selecionada para oferecer a <span>melhor cobertura</span> de serviços médicos na Baixada Santista. Clique em "<span>Exibir a Rede</span>" para visualizar os detalhes por área ou faça o <span>download</span> completo para ter sempre à mão as informações sobre os profissionais e estabelecimentos que cuidam da sua saúde.
                </p>
                <p className='note'><span>*</span>Em dispositivos móveis (celulares), será possível apenas realizar o download da rede credenciada.</p>

                <div className="div-separate">
                    <span className="style-div-separate"></span>
                </div>

            </div>

            <div className="redes-page">
                {cities.map((city, index) => (
                    <CityCard key={index} city={city} onShowCityModal={openCityModal} />
                ))}
                <CityModal city={selectedCity} isCityOpen={isCityModalOpen} onCityClose={closeCityModal} />
            </div>
        </section>

    );
};

export default Redes;
