const RedesMongagua = [
    {
        especialidade: "Anatomia Patológica",
        estabelecimentos: [
            {
                nome: "Laboratório Gonzaga",
                endereco: "Avenida São Paulo, 1380, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 3448-2906"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Inah Machado De Oliveira",
                endereco: "Avenida Menossian, 76, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 99201-9568"],
            },
        ],
    },
    {
        especialidade: "Citopatologia",
        estabelecimentos: [
            {
                nome: "Laboratório Gonzaga",
                endereco: "Avenida São Paulo, 1380, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 3448-2906"],
            },
        ],
    },
    {
        especialidade: "Clínica Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Dimay Odontologia",
                endereco: "Rua José Menossian, 76, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 3346-1518", "(13) 99775-0532"],
            },
            {
                nome: "Inah Machado De Oliveira",
                endereco: "Avenida Menossian, 76, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 99201-9568"],
            },
            {
                nome: "Rosilda Gomes Lima",
                endereco: "Avenida Dr. Getúlio Vargas, 92, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 97420-7886"],
            },
        ],
    },
    {
        especialidade: "Dentística",
        estabelecimentos: [
            {
                nome: "Dimay Odontologia",
                endereco: "Rua José Menossian, 76, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 3346-1518", "(13) 99775-0532"],
            },
            {
                nome: "Inah Machado De Oliveira",
                endereco: "Avenida Menossian, 76, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 99201-9568"],
            },
            {
                nome: "Rosilda Gomes Lima",
                endereco: "Avenida Dr. Getúlio Vargas, 92, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 97420-7886"],
            },
        ],
    },
    {
        especialidade: "Endodontia",
        estabelecimentos: [
            {
                nome: "Dimay Odontologia",
                endereco: "Rua José Menossian, 76, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 3346-1518", "(13) 99775-0532"],
            },
        ],
    },
    {
        especialidade: "Medicina Laboratorial",
        estabelecimentos: [
            {
                nome: "Laboratório Gonzaga",
                endereco: "Avenida São Paulo, 1380, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 3448-2906"],
            },
        ],
    },
    {
        especialidade: "Odontopediatria",
        estabelecimentos: [
            {
                nome: "Rosilda Gomes Lima",
                endereco: "Avenida Dr. Getúlio Vargas, 92, Centro, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 97420-7886"],
            },
        ],
    },
    {
        especialidade: "Periodontia",
        estabelecimentos: [
            {
                nome: "Tainá Costa",
                endereco: "Avenida Marina, 575, Mongaguá, Mongaguá / SP - CEP 11730-000",
                telefones: ["(13) 3507-3875"],
            },
        ],
    },
];

export default RedesMongagua;