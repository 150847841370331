import "./Questions.css";

import questionimg from "../../../../assets/img-main/question.png"

const Questions = () => {
    return (
        <section className="questions">

            <div className="questions__title">
                <h3>
                    ALGUNS TÓPICOS PARA ATENDIMENTO
                </h3>
            </div>

            <div className="questions__container">
                <div className="questions__item">
                    <img
                        src={questionimg}
                        alt="Ícone de dúvida"
                    />
                    <p>
                        Atendimento online 24 horas
                    </p>
                </div>


                <div className="questions__item">
                    <img
                        src={questionimg}
                        alt="Ícone de dúvida"
                    />
                    <p>
                        Orçamento sem compromisso
                    </p>
                </div>

                <div className="questions__item">
                    <img
                        src={questionimg}
                        alt="Ícone de dúvida"
                    />
                    <p>
                        Tire suas dúvidas
                    </p>
                </div>

                <div className="questions__item">
                    <img
                        src={questionimg}
                        alt="Ícone de dúvida"
                    />
                    <p>
                        Tabelas de valores
                    </p>
                </div>

                <div className="questions__item">
                    <img
                        src={questionimg}
                        alt="Ícone de dúvida"
                    />
                    <p>
                        Dúvidas sobre carências
                    </p>
                </div>

                <div className="questions__item">
                    <img
                        src={questionimg}
                        alt="Ícone de dúvida"
                    />
                    <p>
                        Adquira seu plano de saúde
                    </p>
                </div>
            </div>


        </section>
    )
}

export default Questions;