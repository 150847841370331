const RedesItanhaem = [
    {
        especialidade: "Anatomia Patológica",
        estabelecimentos: [
            {
                nome: "Clínica Santo Expedito LTDA",
                endereco: "Rua Jacome Fajardo, 326, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3411-2400"],
            },
        ],
    },
    {
        especialidade: "Citopatologia",
        estabelecimentos: [
            {
                nome: "Clínica Santo Expedito LTDA",
                endereco: "Rua Jacome Fajardo, 326, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3411-2400"],
            },
        ],
    },
    {
        especialidade: "Clínica Médica",
        estabelecimentos: [
            {
                nome: "Clínica Santo Expedito LTDA",
                endereco: "Rua Jacome Fajardo, 326, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3411-2400"],
            },
        ],
    },
    {
        especialidade: "Geriatria",
        estabelecimentos: [
            {
                nome: "Clínica Santo Expedito LTDA",
                endereco: "Rua Jacome Fajardo, 326, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3411-2400"],
            },
        ],
    },
    {
        especialidade: "Ginecologia",
        estabelecimentos: [
            {
                nome: "Clínica Santo Expedito LTDA",
                endereco: "Rua Jacome Fajardo, 326, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3411-2400"],
            },
        ],
    },
    {
        especialidade: "Mamografia",
        estabelecimentos: [
            {
                nome: "Clínica Santo Expedito LTDA",
                endereco: "Rua Jacome Fajardo, 326, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3411-2400"],
            },
        ],
    },
    {
        especialidade: "Medicina Laboratorial",
        estabelecimentos: [
            {
                nome: "Clínica Santo Expedito LTDA",
                endereco: "Rua Jacome Fajardo, 326, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3411-2400"],
            },
        ],
    },
    {
        especialidade: "Odontologia",
        estabelecimentos: [
            {
                nome: "Radmax",
                endereco: "Rua Julio dos Santos, 34, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3427-6501"],
            },
        ],
    },
    {
        especialidade: "Psicologia",
        estabelecimentos: [
            {
                nome: "Clínica Santo Expedito LTDA",
                endereco: "Rua Jacome Fajardo, 326, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3411-2400"],
            },
        ],
    },
    {
        especialidade: "RX",
        estabelecimentos: [
            {
                nome: "Clínica Santo Expedito LTDA",
                endereco: "Rua Jacome Fajardo, 326, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3411-2400"],
            },
        ],
    },
    {
        especialidade: "Ultrassonografia Geral",
        estabelecimentos: [
            {
                nome: "Clínica Santo Expedito LTDA",
                endereco: "Rua Jacome Fajardo, 326, Itanhaém, Itanhaém / SP - CEP 11740-000",
                telefones: ["(13) 3411-2400"],
            },
        ],
    },
];

export default RedesItanhaem;