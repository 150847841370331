import React from 'react';
import './InfoPage.css'

// import wppinfopage from '../../../assets/img-main/wpplogo.png';
import emailinfopage from '../../../assets/img-main/email-infopage.png'


const InfoPage = ({ title, text, imgSrcDesktop, imgSrcMobile }) => {
    return (
        <section className="info-page">

            <picture className="info-page__img">
                <source media="(min-width: 768px)" srcSet={imgSrcDesktop} />
                <source media="(max-width: 767px)" srcSet={imgSrcMobile} />
                <img src={imgSrcDesktop} alt={title} style={{ maxWidth: '100%', height: 'auto' }} />
            </picture>

            <h1>{title}</h1>
            <p>{text}</p>

            <div className='contato__infopage'>
                {/* <div className='contato__infopage-container'>
                    <img
                        src={wppinfopage}
                        alt='ícone WhatsApp'
                    />
                    <p>
                        (19) 2102-9733
                    </p>
                </div> */}

                <div className='contato__infopage-container'>
                    <img
                        src={emailinfopage}
                        alt='ícone Email'
                    />
                    <p>
                        guiasbaixada@gocaresaude.com.br
                    </p>

                </div>
            </div>
            {/* <a className="info-page_button" href="/aplicativo">
                <button>
                    Baixe o App para Acessar
                </button>
            </a> */}
        </section>
    );
};

export default InfoPage;
