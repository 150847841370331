import React from 'react';
import { Helmet } from 'react-helmet';

import InfoPage from '../InfoPage';

import imgautorizacao from '../../../../assets/img-main/infopage/Autorizacao.png';
import imgautorizacao_mobile from '../../../../assets/img-main/infopage/Autorizacao-mobile.png';

const Autorizacao = () => (

    <>
        <Helmet>
            <title>Autorização de Procedimentos | GoCare Saúde Litoral</title>
            <meta name="description" content="Solicite a autorização dos seus procedimentos médicos de forma fácil e rápida." />
        </Helmet>
        <InfoPage
            title="Autorização de Procedimentos"
            text="Solicite a autorização dos seus procedimentos médicos de forma fácil e rápida:"
            imgSrcDesktop={imgautorizacao}
            imgSrcMobile={imgautorizacao_mobile}
        />
    </>
);

export default Autorizacao;
