import './Contact.css';
import { Helmet } from 'react-helmet';

import Channel from './channel/Channel';
import Questions from './questions/Questions';
import usePageTracking from '../../../hooks/usePageTracking.js';

const Contact = () => {

    usePageTracking();

    return (
        <section className="atendimento">

            <Helmet>
                <title>Dúvidas Frequentes | GoCare Saúde Litoral</title>
                <meta name="description"
                    content="Encontre respostas para as dúvidas mais comuns sobre os planos de saúde da GoCare Saúde no Litoral. Saiba tudo sobre carências, coberturas e benefícios dos nossos planos de saúde na Baixada Santista." />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "GoCare Saúde Litoral",
                        "url": "https://gocarelitoral.com.br",
                        "logo": "https://gocarelitoral.com.br/static/media/logo.ae940211769127fa78a0.png",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+55 13 98126-0507",
                            "contactType": "sales",
                            "areaServed": "BR",
                            "availableLanguage": ["Portuguese"],
                            "description": "Encontre respostas para as dúvidas mais comuns sobre os planos de saúde da GoCare Saúde no Litoral. Saiba tudo sobre carências, coberturas e benefícios dos nossos planos de saúde na Baixada Santista."
                        },
                        "sameAs": [
                            "https://www.facebook.com/gocare.litoral",
                            "https://www.instagram.com/gocare.litoral"
                        ]
                    })}
                </script>

            </Helmet>

            <div className="atendimento__title">
                <h2>
                    CANAIS DE ATENDIMENTO DIRECIONADOS ÀS VENDAS
                </h2>
            </div>

            <Channel />
            <Questions />

        </section>
    )
}

export default Contact;