import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import "./FormConfirmation.css";
import usePageTracking from '../../../../hooks/usePageTracking';
import useConversionEvent from '../../../../hooks/useConversionEvent';

const FormConfirmation = () => {
  usePageTracking();

  // Dispara os eventos de conversão
  useConversionEvent('conversion_event_submit_lead_form_8');
  useConversionEvent('conversion_event_submit_lead_form');

  const navigate = useNavigate();

  return (
    <section className="confirmation__container">
      <Helmet>
        <title>Formulário Enviado | GoCare Saúde Litoral</title>
        <meta name="description" content="O formulário foi enviado com sucesso!" />
      </Helmet>

      <div className="form-confirmation">
        <h2 className="form-confirmation__title">Formulário Enviado com Sucesso!</h2>
        <p className="form-confirmation__message">
          Obrigado por entrar em contato conosco. Um de nossos corretores irá contatá-lo em breve. Agradecemos a sua paciência.
        </p>
        <p className="form-confirmation__details">
          Enquanto isso, sinta-se à vontade para explorar nosso site ou <a href="/contato" className="form-confirmation__link">entrar em contato conosco</a> se tiver alguma dúvida adicional.
        </p>
        <button className="form-confirmation__button" onClick={() => navigate('/')}>Voltar à Página Inicial</button>
      </div>
    </section>
  );
};

export default FormConfirmation;
