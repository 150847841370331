import { Helmet } from 'react-helmet';

import './About.css';

import AboutInfo from './aboutinfo/AboutInfo.js';
import Abrangencia from './abrangencia/Abrangencia.js';
import Mission from './mission/Mission.js';
import Cobertura from './cobertura/Cobertura.js';
import usePageTracking from '../../../hooks/usePageTracking.js';

import family from '../../../assets/img-main/family.png';

const About = () => {

    usePageTracking();

    return (
        <div className="about__container">

            <Helmet>
                <title>Sobre Nós | GoCare Saúde Litoral</title>
                <meta name="description"
                    content="Saiba mais sobre a GoCare Saúde no Litoral, a empresa que oferece planos de saúde acessíveis e de qualidade para toda a Baixada Santista. Nossa missão é proporcionar saúde e bem-estar para você e sua família." />
            </Helmet>

            <section className='about__banner-container'>

                <div className='about__banner'>
                    <img
                        className='banner__img'
                        src={family}
                        alt="Imagem de uma família"
                    />
                </div>

                <AboutInfo />
            </section>

            <section className="additional__info">
                <Mission />
            </section>

            <section className="abrangencia">
                <Abrangencia />
            </section>

            <section className="cobertura">
                <Cobertura />
            </section>
        </div>

    )
}

export default About;