import React from 'react';

const GTMNoScript = () => (
    <noscript>
        <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-M8NR66DB"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
            title="Google Tag Manager"
        ></iframe>
    </noscript>
);

export default GTMNoScript;
