import React from 'react';
import './CityCard.css';

const CityCard = ({ city, onShowCityModal }) => {
    return (
        <div className="city-card">
            <h3>Rede Credenciada de<br/> {city.nome}</h3>
            <button className='city-card-button' onClick={() => onShowCityModal(city)}>Exibir a Rede</button>
            <a href={city.downloadUrl} download>
                <button>Download da Rede</button>
            </a>
        </div>
    );
};

export default CityCard;
