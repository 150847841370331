import './Aplicativo.css';
import { Helmet } from 'react-helmet';

import usePageTracking from '../../../hooks/usePageTracking';


import applestore from "../../../assets/img-main/appsteps/apple.png";
import playstore from "../../../assets/img-main/appsteps/playstore.png";

import step01_mobile from "../../../assets/img-main/appsteps/Step01-02-mobile.png";
import step01 from "../../../assets/img-main/appsteps/Step01-02.png";
import step02 from "../../../assets/img-main/appsteps/Step02-02.png";
import step03 from "../../../assets/img-main/appsteps/Step03-02.png";
import step04 from "../../../assets/img-main/appsteps/Step04-02.png";
import step05 from "../../../assets/img-main/appsteps/Step05-02.png";
import step06 from "../../../assets/img-main/appsteps/Step02-02.png";
import step07 from "../../../assets/img-main/appsteps/Step06-02.png";

const Aplicativo = () => {

    usePageTracking();

    const images = [
        { small: step01_mobile, large: step01 }
    ]
    return (
        <section className="aplicativo">
            <Helmet>
                <title>Aplicativo GoCare | GoCare Saúde Litoral</title>
                <meta name="description"
                    content="Descubra a praticidade da carteirinha GoCare Saúde agora no seu celular. Acesse seu cartão digital, rede credenciada, guias, boletos e muito mais pelo aplicativo GoCare." />
            </Helmet>

            <div className="aplicativo_apresentacao">

                <div className='aplicativo_titulo'>

                    <div className='aplicativo_titulo-elementos'>

                        {images.map((image, index) => (
                            <picture key={index}>
                                <source media="(min-width: 768px)" srcSet={image.large} />
                                <source media="(max-width: 767px)" srcSet={image.small} />
                                <img
                                    src={image.large}
                                    alt={`Slide ${index + 1}`}
                                />
                            </picture>
                        ))}

                    </div>

                    <p>
                        A <strong>Go</strong>Care criou um aplicativo para você ter acesso às informações do seu plano de saúde com mais facilidade no dia a dia. Conheça alguns dos recursos disponíveis e veja o passo a passo para acessar.
                    </p>

                </div>

                <div className='container-download_botoes'>
                    <p>
                        Clique nos botões abaixo para baixar o nosso app.
                    </p>

                    <div className='download_botoes'>

                        <a
                            href="https://play.google.com/store/apps/details?id=com.turingbits.gocare"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className='aplicativo_download'
                                src={playstore}
                                alt="Disponível na Play Store"
                            />
                        </a>

                        <a
                            href="https://apps.apple.com/br/app/gocare/id1583661490"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className='aplicativo_download'
                                src={applestore}
                                alt="Disponível na Apple Store"
                            />
                        </a>
                        
                    </div>

                </div>

            </div>

            <div className='aplicativo_steps-descricao'>
                {/* Passo 2 */}
                <div className='aplicativo_steps'>

                    <div className='aplicativo_descricao'>

                        <h3>
                            A praticidade da carteirinha <strong>GoCare Saúde</strong> agora no seu celular!
                        </h3>

                        <div class="div-separate-app">
                            <span class="style-div-separate-app"></span>
                        </div>

                        <p>
                            <strong>Cartão GoCare: </strong>Agora na tela do seu celular;
                        </p>
                        <p>
                            <strong>Rede: </strong>Acesso a sua rede credenciada;
                        </p>
                        <p>
                            <strong>Guias: </strong>Acompanhamento das suas guias;
                        </p>
                        <p>
                            <strong>Boleto: </strong>Acesso ao boleto para planos pessoa física;
                        </p>
                        <p>
                            <strong>PIN-SS Comportamento Cadastral: </strong>Informações sobre seus dados cadastrais e do plano contratado;
                        </p>
                        <p>
                            <strong>PIN-SS Comportamento de Utilização: </strong>Informações sobre suas consultas, exames, terapias e internação.
                        </p>

                    </div>

                    <div className='aplicativo_gocare'>
                        <img
                            src={step02}
                            alt="Passo a passo 2"
                        />
                    </div>

                </div>

                {/* Passo 3 */}
                <div className='aplicativo_steps'>

                    <div className='aplicativo_gocare'>
                        <img
                            src={step03}
                            alt="Passo a passo 3"
                        />
                    </div>

                    <div className='aplicativo_descricao'>
                        <p>
                            Para <strong>o primeiro acesso no app</strong>, insira apenas o seu CPF e clique em <strong>"Cadastrar conta ou nova senha"</strong>.
                        </p>
                    </div>

                </div>

                {/* Passo 4 */}
                <div className='aplicativo_steps'>

                    <div className='aplicativo_descricao'>
                        <p>
                            Agora, <strong>digite sua data de nascimento</strong> e <strong>crie uma senha</strong>. Em seguida, clique em <strong>"Cadastrar nova senha"</strong>.
                        </p>
                    </div>

                    <div className='aplicativo_gocare'>
                        <img
                            src={step04}
                            alt="Passo a passo 4"
                        />
                    </div>

                </div>

                {/* Passo 5 */}
                <div className='aplicativo_steps'>

                    <div className='aplicativo_gocare'>
                        <img
                            src={step05}
                            alt="Passo a passo 5"
                        />
                    </div>

                    <div className='aplicativo_descricao'>
                        <p>
                            <strong>Após inserir sua senha</strong>, você receberá a mensagem: <strong>"Senha cadastrada com sucesso"</strong>.
                        </p>
                    </div>

                </div>

                {/* Passo 6 */}
                <div className='aplicativo_steps'>

                    <div className='aplicativo_descricao'>
                        <h4>
                            <strong>Pronto!</strong>
                        </h4>
                        <p>Todas as informações que você precisa estarão disponíveis.</p>

                        <div class="div-separate-app">
                            <span class="style-div-separate-app"></span>
                        </div>

                        <h5>
                            <strong>Dicas:</strong>
                        </h5>
                        <ul>
                            <li>
                                Se o beneficiário for o responsável financeiro e também o titular do plano, ele poderá visualizar todas as informações do plano e dos dependentes.
                            </li>

                            <li>
                                Se o beneficiário for apenas o responsável financeiro, o cadastro deve ser realizado com o CPF dos dependentes.
                            </li>

                            <li>
                                Quando desejar verificar dois cadastros no mesmo celular, é necessário, após o primeiro acesso, usar o comando "Sair" nas configurações para incluir o outro CPF para consulta.
                            </li>
                        </ul>
                    </div>

                    <div className='aplicativo_gocare'>
                        <img
                            src={step06}
                            alt="Passo a passo 6"
                        />
                    </div>

                </div>

                {/* Passo 7 */}
                <div className='aplicativo_steps'>

                    <div className='aplicativo_gocare'>
                        <img
                            src={step07}
                            alt="Passo a passo 7"
                        />
                    </div>

                    <div className='aplicativo_descricao'>
                        <p>
                            Para verificar <strong>dois cadastros no mesmo celular</strong>, é necessário acionar o comando <strong>"Sair"</strong> nas configurações após o primeiro acesso. Em seguida, <strong>insira o outro CPF</strong> para uma nova consulta e siga o passo a passo novamente.
                        </p>
                    </div>

                </div>
            </div>
        </section >
    )
}

export default Aplicativo;