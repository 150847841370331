import { useEffect } from 'react';

/**
 * Hook para disparar eventos de conversão do Google Analytics.
 * 
 * @param {string} eventName - Nome do evento de conversão.
 * @param {Object} eventParams - Parâmetros adicionais do evento (opcional).
 */
const useConversionEvent = (eventName, eventParams = {}) => {
  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', eventName, eventParams);
    } else {
      console.warn(`gtag não está disponível para o evento: ${eventName}`);
    }
  }, [eventName, eventParams]);
};

export default useConversionEvent;
